import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"height":"260","width":"300"}},[_c(VCardTitle,[_c('h3',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.product.title)+" ")])]),_c(VCardText,[_c(VListItem,{staticClass:"d-flex flex-column ma-0 pa-0",staticStyle:{"height":"60px","overflow":"hidden"}},[_vm._v(" "+_vm._s(_vm.product.description)+" ")]),(_vm.product.type === '2')?_c(VListItemContent,{staticStyle:{"text-align":"right","margin-right":"5px","height":"60px"}},[(_vm.product.maxAllowedEntrances !== null)?_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t('subscriptionProduct-amountOfEntrances', { amount: _vm.product.maxAllowedEntrances }))+" ")]):_vm._e(),_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('subscriptionProduct-price', {price: _vm.globalConvertMinorCurrencyToLocal(_vm.product.priceIncludingVatInMinorCurrency)})))])],1):_vm._e(),_c(VSpacer),_c(VCardActions,{staticClass:"d-flex flex-column ma-0",attrs:{"height":"100%"}},[_c('div',{staticClass:"d-flex align-center justify-space-between",staticStyle:{"width":"100%"}},[_c(VBtn,{attrs:{"disabled":_vm.amount <= 0,"color":"primary"},on:{"click":function($event){return _vm.removeProduct()}}},[_vm._v(" - ")]),_c('b',{staticStyle:{"font-size":"25px"}},[_vm._v(" "+_vm._s(_vm.amount)+" ")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addProduct()}}},[_vm._v(" + ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }