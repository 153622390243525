<template>
    <v-card
        height="260"
        width="300"
    >
        <v-card-title>
            <h3 class="headline">
                {{ product.title }}
            </h3>
        </v-card-title>
        <v-card-text>
            <v-list-item
                class="d-flex flex-column ma-0 pa-0"
                style="height: 60px; overflow: hidden;"
            >
                {{ product.description }}
            </v-list-item>


            <!-- Only valid for subscription products -->
            <v-list-item-content
                v-if="product.type === '2'"
                
                style="text-align: right; margin-right: 5px; height: 60px;"
            >
                <v-list-item-title v-if="product.maxAllowedEntrances !== null">
                    {{ $t('subscriptionProduct-amountOfEntrances', { amount: product.maxAllowedEntrances }) }}
                </v-list-item-title>
                <v-list-item-title>{{ $t('subscriptionProduct-price', {price: globalConvertMinorCurrencyToLocal(product.priceIncludingVatInMinorCurrency)}) }}</v-list-item-title>
            </v-list-item-content>
            
            <v-spacer />

 
            <v-card-actions
                height="100%"
                class="d-flex flex-column ma-0"
            >
                <div
                    class="d-flex align-center justify-space-between"
                    style="width:100%"
                >
                    <v-btn
                        :disabled="amount <= 0"
                        color="primary"
                        @click="removeProduct()"
                    >
                        -
                    </v-btn>
                    
                    <b
                        style="font-size:25px;"
                    >
                        {{ amount }}
                    </b>

                    <v-btn
                        color="primary"
                        @click="addProduct()"
                    >
                        +
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card-text>
    </v-card>   
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            amount : 0
        }
    },
    watch: {
        amount: function(val){
            this.amount = val < 0 ? 0 : val
        }
    },
    methods: {
        addProduct() {
            this.amount++
            this.emitProductChange()
        },
        removeProduct() {
            this.amount--
            this.emitProductChange()
        },
        emitProductChange(){
            this.$emit('productadmountchanged', {
                productId: this.product.id,
                amount: this.amount
            })
        }
    }
}
</script>